export default function recaptchaToken({ action, siteKey }) {
  return new Promise((resolve, reject) => {
    try {
      if (!siteKey) {
        reject(new Error('siteKey is required'));
      }
      if (!window.grecaptcha) {
        reject(new Error('recaptcha not initialized'));
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(siteKey, { action }).then(resolve, reject);
      });
    } catch (error) {
      reject(error);
    }
  });
}
