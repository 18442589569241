import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ['results'];
  static values = {
    url: String,
  };

  loadResults(event) {
    if (this.requestController) {
      this.requestController.abort();
    }

    const requestController = new AbortController();
    try {
      fetch(
        `${this.urlValue}?search=${encodeURIComponent(
          event.currentTarget.value
        )}`,
        { signal: requestController.signal }
      )
        .then(async (response) => {
          if (
            !requestController.signal.aborted &&
            response.statusText === 'OK'
          ) {
            this.resultsTarget.innerHTML = await response.text();
          }
        }, console.error)
        .then(
          () => (this.requestController = null),
          () => (this.requestController = null)
        );
      this.requestController = requestController;
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }
}
