/* global Stripe */

import $ from 'jquery';
import recaptchaToken from './recaptcha';

const recaptchaSiteKey = document.currentScript.dataset.recaptchaSiteKey;

export default function initializePayment() {
  'use strict';
  /* PRICE */
  var globalValueInput = $('#global-value-option');
  var initialPriceElement = $('#original-price');

  if (initialPriceElement.length === 0) {
    return;
  }

  initialPriceElement.text(
    globalValueInput[0].selectedOptions[0].dataset.price
  );

  globalValueInput.change(function (event) {
    initialPriceElement.text(
      event.currentTarget.selectedOptions[0].dataset.price
    );
  });

  /* COUPON CODE */
  var applyButton = $('button.apply-coupon-code');

  if (applyButton) {
    applyButton.on('click', function () {
      var couponCode = $('input[name="coupon_code"]').val(),
        errorContainer = $('.code-error');

      // Check the code against the database
      $.ajax({
        url: '/check-code',
        dataType: 'json',
        data: {
          coupon_code: couponCode,
          global_value_id: $('#global-value-option').val(),
        },
      })
        .done(function (data) {
          // Erase any old error messages
          errorContainer.text('');
          errorContainer.addClass('hidden');

          if (data.coupon_code_status == 'valid') {
            // Show the new price if the code is valid
            $('#original-price').addClass('old');
            $('#discounted-price')
              .addClass('new')
              .removeClass('hidden')
              .text(data.new_price);

            if (data.new_price === 0) {
              // Hide all of the stripe related fields
              $('[data-stripe]').addClass('hidden');
            }
          } else if (data.coupon_code_status == 'invalid') {
            // The code isn't valid, display the new error message
            errorContainer.text(data.message);
            errorContainer.removeClass('hidden');
          } else {
            // Something went wrong on the server, display an apology error message
            errorContainer.text(
              "We're sorry, but something went wrong on our end. Please try again later."
            );
            errorContainer.removeClass('hidden');
          }
        })
        .fail(function () {
          console.log('Call failed.'); // eslint-disable-line no-console
        });
    });
  }

  /* STRIPE */
  var $form = $('#new_filing_promotion');
  $form.submit(function (_event) {
    var submitButton = document.querySelector('input[type="submit"]');

    if (submitButton) {
      submitButton.setAttribute('disabled', 'true');
      submitButton.value = 'Promoting...';
    }

    if ($('[data-stripe').first().hasClass('hidden')) {
      // If the credit card stuff is hidden, don't bother with Stripe
      return true;
    }
    // Disable the submit button to prevent repeated clicks:
    $form.find('[type="submit"]').prop('disabled', true);

    // Request a token from Stripe:
    Stripe.card.createToken($form, stripeResponseHandler);

    // Prevent the form from being submitted:
    return false;
  });

  function stripeResponseHandler(status, response) {
    // Grab the form:
    var $form = $('#new_filing_promotion');

    if (response.error) {
      // Problem!
      $form
        .find('[type="submit"]')
        .prop('disabled', false)
        .val('Promote This Filing');

      // Show the errors on the form:
      $form.find('.payment-errors').text(response.error.message);
    } else {
      // Token was created!

      // Get the token ID:
      var token = response.id;

      // Insert the token ID into the form so it gets submitted to the server:
      $form.append($('<input type="hidden" name="stripeToken">').val(token));

      recaptchaToken({
        action: 'payment',
        siteKey: recaptchaSiteKey,
      }).then((token) => {
        $form.append(
          $('<input type="hidden" name="g-recaptcha-response2">').val(token)
        );

        // Submit the form:
        $form.get(0).submit();
      }, console.error); // eslint-disable-line no-console
    }
  }
}
