import { Controller } from '@hotwired/stimulus';

function template({ height, width, url }) {
  return `<iframe src="${url}" frameborder="0" scrolling="no" height="${encodeURIComponent(
    height
  )}px" width="${encodeURIComponent(width)}px"></iframe>`;
}

export default class extends Controller {
  static values = {
    baseUrl: String,
  };
  static targets = [
    'chartHeightNumberInput',
    'chartHeightSliderInput',
    'code',
    'headerStyleSelect',
    'heightNumberInput',
    'heightSliderInput',
    'hideChartLegendCheckbox',
    'iframe',
    'statsContainer',
    'widthNumberInput',
    'widthSliderInput',
    'widgetSelect',
  ];

  connect() {
    this.baseUrl = this.widgetSelectTarget.value;
    this.chartHeight = this.chartHeightNumberInputTarget.value;
    this.headerStyle = this.headerStyleSelectTarget.value;
    this.height = this.heightNumberInputTarget.value;
    this.hideChartLegend = this.hideChartLegendCheckboxTarget.checked;
    this.width = this.widthNumberInputTarget.value;
    this.render();
  }

  render() {
    const result = template({
      height: this.height,
      width: this.width,
      url: this.url(),
    });

    this.codeTarget.replaceChildren(result);

    this.iframeTarget.height = `${this.height}px`;
    if (this.iframeTarget.src !== this.url()) {
      this.iframeTarget.src = this.url();
    }
    this.iframeTarget.width = `${this.width}px`;

    this.chartHeightNumberInputTarget.value = this.chartHeight;
    this.chartHeightNumberInputTarget.value = this.chartHeight;
    this.heightNumberInputTarget.value = this.height;
    this.heightSliderInputTarget.value = this.height;
    this.widthNumberInputTarget.value = this.width;
    this.widthSliderInputTarget.value = this.width;

    this.statsContainerTarget.innerHTML = `
      <turbo-frame id="stats" src="/admin/widget_stats?from=${encodeURIComponent(
        this.baseUrl
      )}">
        Loading stats...
      </turbo-frame>
    `;
  }

  chartHeightChanged(event) {
    this.chartHeight = event.target.value;
    this.render();
  }

  headerStyleChanged(event) {
    this.headerStyle = event.target.value;
    this.render();
  }

  heightChanged(event) {
    this.height = event.target.value;
    this.render();
  }

  hideChartLegendChanged(event) {
    this.hideChartLegend = event.target.checked;
    this.render();
  }

  url() {
    if (this.hideChartLegend) {
      return `${this.baseUrl}?chartHeight=${encodeURIComponent(
        this.chartHeight
      )}px&hideLegend=${this.hideChartLegend}&headerStyle=${encodeURIComponent(
        this.headerStyle
      )}`;
    }
    return `${this.baseUrl}?chartHeight=${encodeURIComponent(
      this.chartHeight
    )}px&headerStyle=${encodeURIComponent(this.headerStyle)}`;
  }

  widgetChanged(event) {
    this.baseUrl = event.target.value;
    this.render();
  }

  widthChanged(event) {
    this.width = event.target.value;
    this.render();
  }
}
