import $ from 'jquery';
import Cropper from 'cropperjs';

export default function dqCropper() {
  'use strict';

  // check for support
  if (!window.Blob || !window.File || !window.FileList || !window.FileReader) {
    return;
  }

  function isValidFile(input) {
    return (
      input.files &&
      input.files.length > 0 &&
      input.files[0].type.substr(0, 'image/'.length) === 'image/'
    );
  }

  $('[data-croppable="true"]').on('change', function (event) {
    var input = event.currentTarget;
    var aspectRatio = $(input).data('aspectRatio');

    if (!isValidFile(input)) {
      if ($(input).data('cropper')) {
        $(input).data('cropper').destroy();
        $(input).data('cropper', null);
        $(input).next().find('img').attr('src', '');
      }
      return;
    }

    // initialize
    if (!$(input).next().is('.cropper-container')) {
      $(input).after('<div class="cropper-container"><img /></div>');
      $(input)
        .closest('form')
        .on('submit', function (event) {
          if ($(input).data('cropper')) {
            var $form = $(event.currentTarget);
            var $croppedImage = $('<input>', {
              name: $(input).attr('name'),
              type: 'hidden',
              value: $(input).data('cropper').getCroppedCanvas().toDataURL(),
            });
            $form.append($croppedImage);
            $(input).prop('disabled', true);
          }
        });
    }

    var file = input.files[0];
    var reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        var image = $(input).next().find('img')[0];
        image.src = reader.result;

        if ($(input).data('cropper')) {
          $(input).data('cropper').destroy();
        }

        const cropper = new Cropper(image, {
          aspectRatio: aspectRatio, // 750x375 for Reg CF
          autoCropArea: 1,
          movable: false,
          rotatable: false,
          scalable: false,
          zoomable: false,
          zoomOnTouch: false,
          zoomOnWheel: false,
        });
        window.__cropper = cropper;
        $(input).data('cropper', cropper);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  });
}
