import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    msg: String,
  };

  confirm(event) {
    const confirmed = window.confirm(this.msgValue);
    if (!confirmed) {
      event.preventDefault();
    }
  }
}
