import '@hotwired/turbo-rails';
import $ from 'jquery';
import registerCropperPlugin from '../lib/dq-cropper';
import registerExpandPlugin from '../lib/expand';
import registerPaymentPlugin from '../lib/payment';
import registerStickyTop from '../lib/stickyTop';

import '../controllers';

function appReady() {
  registerExpandPlugin(document);

  if ($('#new_filing_promotion') && $('button.apply-coupon-code')) {
    registerPaymentPlugin();
  }

  registerCropperPlugin();
  registerStickyTop();
}

$(document).ready(appReady);
