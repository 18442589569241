function clickHandler(event) {
  const expandTriggerId = event.currentTarget.dataset.expand;

  if (expandTriggerId) {
    const expandTarget = document.getElementById(expandTriggerId);
    expandTarget.classList.toggle('hidden');
  }
}

export default function register(document) {
  const expandTriggerList = document.querySelectorAll('[data-expand]');

  expandTriggerList.forEach(function (expandTrigger) {
    expandTrigger.addEventListener('click', clickHandler);
  });
}
