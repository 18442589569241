export default function setupStickyTop() {
  if (window.IntersectionObserver === undefined) {
    return;
  }
  document
    .querySelectorAll('[data-sticky-top-intersection-observer="true"]')
    .forEach((target) => {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio === 0) {
          target.nextElementSibling.classList.add('sticky-top');
        } else {
          target.nextElementSibling.classList.remove('sticky-top');
        }
      });
      observer.observe(target);
    });
}
